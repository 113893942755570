.nextFormActions {
    margin: 2rem 0;
    display: flex;
    text-align: center;
}

.nextFormActions .finishBtns .btn {
    text-transform: uppercase;
}

.finishBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
