.orderFormFenceModelHidden {
  display: none;
}

.orderFormFenceTypeHidden {
  display: none;
}

.formActions .btn {
  margin-bottom: 1rem;
}

.formInputs {
  display: flex;
  align-items: baseline;
}

.error {
  font-size: .8rem;
  text-align: center;
  /*  important because of .text-muted */
  color: #ff0000 !important;
  white-space: pre;
}

.orderForm .card-header {
  display: flex;
}

.orderFormTitle {
  width: calc(100% - 60px); /*  delete button */
}

.orderFormDeleteBtn {
  color: #ff0000;
}

.orderFormTitle .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #ff0000;
}

.orderFormTitle svg {
  color: #ff0000;
}

.orderFormTitle .btn:focus {
  text-decoration: none;
}

.orderFormTitle .btn:hover {
  color: #000;
  text-decoration: none;
}

.orderFormTitle .btn:hover svg {
  color: #000;
}

.responseThSingular {
  width: 5%;
}

.responseThName {
  width: 30%;
}

.responseThMaterial {
  width: 35%;
}

.responseThQuantity {
  width: 15%;
}

.responseThMeasure {
  width: 15%;
}

.orderFormComment label {
  font-weight: bold;
}
