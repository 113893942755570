#footer {
  align-items: center;
  font-size: 12px;
  padding: 1rem;
}

#footerRow {
  align-items: center;
}

#footerRights {
  text-align: center;
}

#footerCompany p {
  text-align: right;
  margin: 0;
}

#footerLogoContainer {
  text-align: left;
}

#footerLogoContainer img {
  width: 300px;
  height: auto;
}
