#gettingStartedForm {
  margin-top: 2rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e0e0e0;
}

#gettingStartedFormLeftCol {
  padding-left: 0;
}

#gettingStartedFormLeftCol input {

}

#gettingStartedFormLeftCol input :focus {
  border: none;
  outline: none;
}

#gettingStartedFormRightCol {
  padding-right: 0;
  padding-top: 2rem;
}

#gettingStartedBtn {
  width: 100%;
  text-transform: uppercase;
}

#gettingStartedBtn :focus {
  box-shadow: #e0e0e0;
}







