#pdfButtonSection {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  background: #e0e0e0;
}

#pdfButtonSection .error {
  font-size: 1rem;
}

#pdfBtnContainer {
  display: flex;
}

#pdfBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .4s;
  color: #000;
}

#pdfBtn svg {
  margin-bottom: 0.5rem;
  color: #ff0000;
}

#pdfBtn:hover {
  color: #ff0000;
  transition: .4s;
}
