main {
    min-height: calc(100vh - 98px); /* 98px footer */
    display: flex;
    flex-direction: column;
}

.formInputs {
    display: flex;
    min-height: 134px; /* 134px when errors appears */
}

.formInputs > .form-group {
    width: 160px;
    margin-right: 2rem;
}

.polesInput {
    width: 160px;
    margin-right: 2rem;
}

.hingesInput {
    width: 160px;
    margin-right: 2rem;
}

.formInputs > .form-group:last-child {
    margin-right: 0;
}

.formActions {
    display: flex;
    justify-content: flex-end;
}

.formGroupNotVisible {
    visibility: hidden;
}

.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active {
    background-color: #ff0000;
    border-color: #ff0000;
    transition: .6s;
}

.btn-danger:focus {
    box-shadow: none;
}

.btn-danger:hover {
    background-color: #fff;
    color: #ff0000;
    transition: .6s;
}

.spinner {
    animation: fa-spin 2s linear infinite;
}

.modal {
    background: rgba(0, 0, 0, .4);
}
